<template>
  <div class="main">
    <div class="main_content">
      <div class="page1" v-show="nav_index == 0">
        <img src="../assets/images/img1.jpeg" alt="" />
        <img src="../assets/images/img2.jpeg" alt="" />
        <img src="../assets/images/img3.jpeg" alt="" />
        <img src="../assets/images/img4.jpeg" alt="" />
        <img src="../assets/images/img5.jpeg" alt="" />
        <img src="../assets/images/img6.jpeg" alt="" />
      </div>
      <div class="page2" v-show="nav_index == 1">
        <h5 class="cooperation_title">矿机</h5>
        <div class="img_container">
          <img src="../assets/images/dig.png" alt="" />
        </div>
        <span class="desc">
          一台矿机 <i>160U</i> 可以挖出 价值 <i>320U</i> 的DMI。
        </span>
        <p>
          <a
            class="download_url"
            href="https://mu-panda.oss-cn-beijing.aliyuncs.com/apps/CE-0.0.60.apk"
            >下载APP</a
          >
        </p>
      </div>
      <div class="page3" v-show="nav_index == 2">
        <div class="panel">
          <h5 class="cooperation_title">合作伙伴</h5>
          <div class="cooperation_list">
            <img src="../assets/images/cooperationBNB.png" alt="" />
            <img src="../assets/images/open_sesame_light.png" alt="" />
          </div>
        </div>
        <p>
          <a
            class="download_url"
            href="https://mu-panda.oss-cn-beijing.aliyuncs.com/apps/CE-0.0.60.apk"
            >下载APP</a
          >
        </p>
      </div>
    </div>
    <div class="nav_bar">
      <div
        v-for="(item, index) in navList"
        :key="index"
        class="nav_bar_item"
        @click="nav_index = index"
        :class="nav_index === index ? 'active' : ''"
      >
        <img :src="nav_index === index ? item.activeImg : item.img" alt="" />
        <span>{{ item.title }} </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  data() {
    return {
      navList: [
        {
          title: "首页",
          img: require("../assets/images/ftts.png"),
          activeImg: require("../assets/images/ftts.png"),
        },
        {
          title: "挖矿",
          img: require("../assets/images/LPmining.png"),
          activeImg: require("../assets/images/LPmining_active.png"),
        },
        {
          title: "设置",
          img: require("../assets/images/gn1.png"),
          activeImg: require("../assets/images/gn2.png"),
        },
      ],
      nav_index: 0,
    };
  },
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.img_container {
  padding: 5%;
}
.desc {
  font-size: 20px;
  color: #ffb751;
  padding: 0 10px;
  display: block;
  i {
    font-weight: bold;
    font-style: normal;
    font-size: 26px;
    color: yellow;
  }
}
.download_url {
  font-size: 20px;
  color: yellow;
  margin-top: 40%;
  display: block;
}
.cooperation_title {
  font-size: 36px;
  font-weight: bold;
  color: yellow;
  padding: 10px 0;
}
.page3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.panel {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAsYAAACwCAYAAAAMjKhAAAAFC0lEQVR4nO3dsWqcRxiF4SPJIBB2GqUWmEBs0vgKkialq4Bu0BfhdGli30CqyCkiUB03VhC4UBTG36+gKLLrWOd5QM2ihZ3ZKV5+Zmd2jo+P8wlfJPk6yVGSL5McJNn71BsAAOB/4jLJRZI/kpwl+S3Ju499tAcfef1hkm+TPE2y65sFAOAztB7oPtr+Hm99e5LkdZLz28O5K4zXm54n2fftAwBwj6wHvt8k+SrJyySnN4d2+2nwsyQ/iGIAAO6x/a15n90c4s0wXk+Kv0+yYxUAAHDP7Wzt+/h6mNdh/HDbPiGKAQBosbM18Grhf8L4O9snAAAotL+18IcwXr/Se2IVAABQarXwo11HsgEAUO5DE+9ul3cAAECzoxXGh5YAAADlDne3a54BAKDZwe52VR4AADTb86M7AADqxWkUAAAwhDEAAPUijAEAYAhjAADqRRgDAMAQxgAA1IswBgCAIYwBAKgXYQwAAEMYAwBQL8IYAACGMAYAoF6EMQAADGEMAEC9CGMAABjCGACAehHGAAAwhDEAAPUijAEAYAhjAADqRRgDAMAQxgAA1IswBgCAIYwBAKgXYQwAAEMYAwBQL8IYAACGMAYAoF6EMQAADGEMAEC9CGMAABjCGACAehHGAAAwhDEAAPUijAEAYAhjAADqRRgDAMAQxgAA1IswBgCAIYwBAKgXYQwAAEMYAwBQL8IYAACGMAYAoF6EMQAADGEMAEC9CGMAABjCGACAehHGAAAwhDEAAPUijAEAYAhjAADqRRgDAMAQxgAA1IswBgCAIYwBAKgXYQwAAEMYAwBQL8IYAACGMAYAoF6EMQAADGEMAEC9CGMAABjCGACAehHGAAAwhDEAAPUijAEAYAhjAADqRRgDAMAQxgAA1IswBgCAIYwBAKgXYQwAAEMYAwBQL8IYAACGMAYAoF6EMQAADGEMAEC9CGMAABjCGACAehHGAAAwhDEAAPUijAEAYAhjAADqRRgDAMAQxgAA1IswBgCAIYwBAKgXYQwAAEMYAwBQL8IYAACGMAYAoF6EMQAADGEMAEC9CGMAABjCGACAehHGAAAwhDEAAPUijAEAYAhjAADqRRgDAMAQxgAA1IswBgCAIYwBAKgXYQwAAEMYAwBQL8IYAACGMAYAoF6EMQAADGEMAEC9CGMAABjCGACAehHGAAAwhDEAAPUijAEAYAhjAADqRRgDAMAQxgAA1IswBgCAIYwBAKgXYQwAAEMYAwBQL8IYAACGMAYAoF6EMQAADGEMAEC9CGMAABjCGACAehHGAAAwhDEAAPUijAEAYAhjAADqRRgDAMAQxgAA1IswBgCAIYwBAKgXYQwAAEMYAwBQL8IYAACGMAYAoF6EMQAADGEMAEC9CGMAABjCGACAehHGAAAwhDEAAPUijAEAYAhjAADqRRgDAMAQxgAA1MsWxpdmAgCAcpcrjC/aZwEAgHoXK4zfts8CAAD13q4wPmufBQAA6p2tMD5J8lf7TAAAUGu18MkK4/Mkb6wDAABKrRY+vz6u7eck760EAADKrAZ+lRvnGP+Z5GWSKysBAIASq31/3HZQ/OuCj9MkP4ljAAAKXG3t+/v1UB/cGvMvSd4leZ5k34oAAOAeer/tlji9ObS7roRe//Aiya9OqwAA4B652hr3xe0ozh1PjK+db/stXid5kuQoyWGSgyR7VgcAAJ+By+2W53Wh3bq7Y50+sXZH/FeSvwGYdULxuIELpgAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background: #1d2633;
  color: #ffb751;
  border-radius: 24px;
  margin: 0 19px 14px;
  padding: 0 24px 14px;
  margin-top: 30px;
  color: #f1f1f1;
  padding-top: 14px;
  border: 1px solid #343d4f;
  margin-top: 20%;
}
.main {
  img {
    display: block;
    width: 100%;
  }
}
.main {
  background: #131922;
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.main_content {
  flex: 1;
  overflow: scroll;
}
.cooperation_list {
  img {
    width: 60%;
    margin-bottom: 20px;
  }
}
.nav_bar {
  height: 50px;
  background: red;
  display: flex;
  justify-items: center;
  align-items: center;
  background: #130c17;
  .nav_bar_item {
    color: #fff;
    flex: 1;
    font-size: 10px;
    img {
      width: 24px;
      display: inline;
    }
    span {
      display: inherit;
    }
    &.active {
      color: rgb(246, 205, 48);
    }
  }
}
</style>
